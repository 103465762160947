import React, { useState } from "react";

import "./style.css";

import { Navigate, useNavigate } from "react-router-dom";
import CheckboxGroup from "./CheckboxGroup";

import header from "../assets/header.jpg";
//import CheckboxGroup from "./CheckboxGroup";
const MyComponent = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [registrationEmail, setRegistrationEmail] = useState(null); // Replace with actual logic for session handling

  const handleSubmit = (e) => {
    e.preventDefault();
    // Valide les champs avant l'enregistrement
    if (email && phone) {
      // Enregistre les données dans le localStorage
      localStorage.setItem("registrationEmail", email);
      localStorage.setItem("registrationPhone", phone);

      // Navigue vers la prochaine page
      navigate("/niveau-un");
    } else {
      alert("Veuillez remplir tous les champs.");
    }
  };
  const navigate = useNavigate();

  return (
    <>
      <section className="lg:px-20 sm:px-10 px-5 min-h-[300px] bg-cover   py-24  md:py-32  flex lg:flex-row flex-col lg:gap-10 lg:justify-between justify-start items-center header-imgk  bg-gradient-to-r from-vert to-jaune  bg-opacity-45">
        <article className="lg:w-7/12 w-full text-white">
          <div className="lg:text-6xl md:text-3xl text-3xl font-extrabold text-center ">
            Gagnez de l'argent avec DJONAN2PAY
          </div>
          <div className="lg:text-lg font-medium my-10 text-center">
            Devenez vendeur ou livreur et boostez vos revenus tout en
            travaillant selon votre emploi du temps ! Rejoignez-nous dès
            aujourd'hui !
          </div>
        </article>

        <article className="lg:w-5/12 w-full">
          <form
            onSubmit={handleSubmit}
            className="rounded-xl min-h-40 bg-white p-4"
          >
            {registrationEmail ? (
              <>
                <header className="text-xl font-extrabold">
                  Heureux de vous revoir, {registrationEmail}
                </header>
                <p className="text-sm text-gray-500 my-5">
                  Voulez vous poursuivre votre inscription ?
                </p>
                <div className="mt-5">
                  <input
                    type="submit"
                    className="bg-gray-500 text-white font-bold w-full rounded-xl p-3 text-center cursor-pointer"
                    name="cancel_process"
                    value="Annuler"
                  />
                </div>
              </>
            ) : (
              <>
                <header className="font-extrabold text-2xl">INSCRIPTION</header>

                <div className="my-4">
                  <label className="font-bold block mb-3">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Entrez votre adresse email"
                    className="block bg-gray-200 rounded-xl p-3 w-full focus:outline-none"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="">
                  <label className="font-bold block mb-3">
                    Numero de telephone
                  </label>
                  <div className="flex lg:gap-5 md:gap-7 gap-4">
                    <div className="lg:w-4/12 md:w-2/12 w-3/12">
                      <div className="w-full bg-gray-200 rounded-xl flex items-center lg:gap-2 h-full px-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 36 36"
                        >
                          <path
                            fill="#f77f00"
                            d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5z"
                          ></path>
                          <path fill="#eee" d="M12 5h12v26H12z"></path>
                          <path
                            fill="#009e60"
                            d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4"
                          ></path>
                        </svg>
                        <div className="md:text-sm text-xs"> +225</div>
                      </div>
                    </div>

                    <div className="lg:w-8/12 md:w-10/12 w-9/12">
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Numero de telephone"
                        className="block bg-gray-200 rounded-xl p-3 w-full focus:outline-none"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="my-4">
                    <input type="checkbox" required id="acceptance" />
                    <label htmlFor="acceptance" className="mb-3 text-sm">
                      En cochant la case, vous acceptez les termes de nos
                      services et notre <a 
          href="https://businessdjonan2pay.com/politique.pdf" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-blue-600 underline"
        >
          politique de confidentialité
        </a>
                    </label>
                  </div>
                </div>

                <div className="mt-5">
                  <button
                    type="submit"
                    className="bg-vert text-white font-bold w-full rounded-xl p-3"
                  >
                    Continuer
                  </button>
                </div>
              </>
            )}
          </form>
        </article>
      </section>

      <section className="lg:px-20 sm:px-10 px-5 min-h-[200px] md:py-20 sm:py-14 pt-10">
        <article>
          <header className="md:text-4xl text-3xl font-bold">
            Pourquoi utiliser DJONAN2PAY ?
          </header>
          <p className="my-10 text-sm text-gray-700 font-medium sm:text-lg lg:w-7/12">
            Nous offrons aux vendeurs et aux livreurs la flexibilité de fixer
            leurs propres horaires, tout en optimisant la gestion des commandes
            et des livraisons pour maximiser leurs revenus et améliorer
            l'expérience client.
          </p>
        </article>

        <article className="grid lg:grid-cols-3 sm:grid-cols-2 sm:gap-10 gap-7 mt-20">
          {/* Add benefit cards */}
          <div>
            <div className="text-2xl text-vert">
              <i className="fas fa-calendar"></i>
            </div>
            <div className="font-bold text-lg my-3">
              Travaillez à votre rythme
            </div>
            <div className="text-sm">
              Bénéficiez d'une flexibilité totale en choisissant vos horaires de
              travail.
            </div>
          </div>

          <div>
            <div className="text-2xl text-vert">
              <i className="fas fa-wallet"></i>
            </div>
            <div className="font-bold text-lg my-3">
              Augmentez vos revenus facilement
            </div>
            <div className="text-sm">
              Rencontrez des clients variés tout en explorant de nouveaux lieux
              et en boostant vos gains.
            </div>
          </div>

          <div>
            <div className="text-2xl text-vert">
              <i className="fas fa-money-bill"></i>
            </div>
            <div className="font-bold text-lg my-3">
              Paiement rapide et fiable
            </div>
            <div className="text-sm">
              Recevez vos gains chaque semaine, en toute sécurité et sans
              tracas.
            </div>
          </div>
        </article>
      </section>

      <section className="lg:px-20 sm:px-10 px-5 min-h-[200px] md:py-20 py-10">
        <article className="bg-vert rounded-xl lg:px-20 lg:pb-0 md:px-10 sm:px-5 p-6 pt-5 flex items-center justify-center">
          <div className="lg:w-5/12">
            <header className="text-white font-extrabold lg:text-4xl md:text-2xl sm:text-lg text-2xl text-center">
              Devenez votre propre patron. Commencez à gagner de l'argent
            </header>
            <p className="my-10 text-white font-medium text-sm text-center">
              Votre inscription ne prendra que 2 min
            </p>
            <div className="text-center pb-2">
              <a
                href="#"
                className="bg-white rounded-xl inline-block lg:p-3 p-2 text-vert font-bold text-sm"
              >
                <span className="text-center">Commencer</span>
              </a>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default MyComponent;
