import React,{useState} from 'react';
import { Clock, Sparkles, X } from 'lucide-react';
import { useNavigate } from "react-router-dom";

import axios from 'axios';
const Alerte = ({ onClose }) => {
    const navigate = useNavigate();
    function checkout() {
        window.CinetPay.setConfig({
            apikey: '14442760016593f277e9fde9.10070620',
            site_id: '5866743',
            notify_url: 'http://mondomaine.com/notify/',
            mode: 'PRODUCTION'
        });
    
        window.CinetPay.getCheckout({
            transaction_id: Math.floor(Math.random() * 100000000).toString(),
            amount: 100,
            currency: 'XOF',
            channels: 'ALL',
            description: 'Test de paiement',
            customer_name: "Joe",
            customer_surname: "Down",
            customer_email: "down@test.com",
            customer_phone_number: "088767611",
            customer_address: "BP 0024",
            customer_city: "Antananarivo",
            customer_country: "CM",
            customer_state: "CM",
            customer_zip_code: "06510", 
        });
    
        window.CinetPay.waitResponse( async function(data) {
            if (data.status === "REFUSED") {
               
                 window.location.reload();
                
            } else if (data.status === "ACCEPTED") {

               // const data = new FormData();

                const idVendeur = localStorage.getItem("id");
                const savedPhone = localStorage.getItem("registrationPhone");

                const base = process.env.REACT_APP_BASE_URL;
                try {
                    const response = await axios.get(`${base}update-solde`, {
                        params: {
                          idVendeur,
                          nouveauSolde:100
                        }
                      });
                   // setLoading(false);

                    navigate("/recu");
                 //   localStorage.setItem("id", response.data.vendeurId);
                   // navigate("/recu");
                 //  setVisible(true)
              
                    //console.log("Form submitted successfully:", response.data);
              
                  
              
                  } catch (error) {
                    //setLoading(false);
                    // navigate("/recu");
                   // alert(error.message);
                    console.error("Error submitting form:", error);
                  }  
               
                /*
                if (alert("Votre paiement a été effectué avec succès")) {
                    window.location.reload();
                }
                    */
            }
        });
    
        window.CinetPay.onError(function(data) {
            console.log(data);
        });
    }
    
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/30 backdrop-blur-sm">
      <div className="relative bg-gradient-to-b from-white to-indigo-50 rounded-3xl shadow-2xl w-full max-w-lg mx-4 overflow-hidden">
        {/* Décorations d'arrière-plan */}
        <div className="absolute top-0 left-0 w-32 h-32 bg-indigo-100 rounded-full -translate-x-16 -translate-y-16" />
        <div className="absolute bottom-0 right-0 w-32 h-32 bg-purple-100 rounded-full translate-x-16 translate-y-16" />
        
        {/* Contenu principal */}
        <div className="relative">
          {/* Barre de poignée */}
          <div className="flex justify-center pt-4">
            <div className="w-12 h-1 bg-gray-200 rounded-full" />
          </div>

          {/* Bouton de fermeture
          
          
           <button
            onClick={onClose}
            className="absolute top-4 right-4 p-1 text-gray-400 hover:text-gray-600 transition-colors"
          >
            <X size={20} />


          </button>
          */}
          
         
          
          {/* En-tête */}
          <div className="flex justify-center mt-8">
            <div className="bg-indigo-100 p-3 rounded-2xl">
              <Clock className="h-8 w-8 text-indigo-600" />
            </div>
          </div>
          
          {/* Texte */}
          <div className="px-6 pt-6 pb-8 text-center">
            <div className="flex items-center justify-center gap-2 mb-2">
              <h2 className="text-xl font-bold text-gray-900">
                Paiement de la caution
              </h2>
              <Sparkles className="h-5 w-5 text-yellow-500" />
            </div>
            <p className="mt-2 text-gray-600">
             Pour terminer votre inscription en tant que vendeur vous devez payer une caution de 10.000 F CFA retirable a tout moment
            </p>
          </div>
          
          {/* Bouton */}
          <div className="px-6 pb-8">
            <button
              onClick={()=>{checkout()}}
              className="w-full py-3 bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 text-white font-medium rounded-xl shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-200"
            >
              Payer maintenant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};



export default Alerte;